/* .header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
} */

.header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 60px;
}

.header-logo {
  height: 35px;
  margin-left: 10px;
}

p.version {
  margin-left: auto;
  color: green;
  font-weight: 400;
  font-size: small;
}

/* Logout button that is cyan colored and has white text */
.logoutButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  font-size: 15px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #556ee6;
  transition: background-color 1s ease;
  margin-left: 5px;
}
