.vital {
  flex: 0 0 25%;
  display: flex;
  flex-direction: row;
  color: white;
  background-color: rgb(50 63 76);
  border-radius: 10px;
  margin: 0.4rem;
  font-size: xx-large;
  padding: 0.5rem 1rem 0.5rem 1rem;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.measurementIcon {
  margin-right: 2px;
}

.vitalValue {
  color: white;
  font-size: 22px;
  font-weight: bold;
}

.iconDesc {
  font-size: 14px;
  color: white;
}

.chartGrid {
  display: flex;
  flex-wrap: wrap;         /* allow items to wrap to next row */
  justify-content: center;
  margin: 0 auto;
  width: 100%;
}

.chartItem {
  /* 
    Force each chart to be ~30% so you get 3 in one row.
    The margin can cause line breaks if the sum of widths
    doesn't fit exactly, so you may tweak to 32% or 28%.
  */
  flex: 0 0 25%;
  margin: 0.5rem 1.2rem 0.5rem 1.2rem;  
  /* If you want some side margin or gap for each chart: */
  /* margin-right: 1rem; */

  /* Or use gap in .chartGrid if your project/browsers support flex gap:
     .chartGrid { gap: 1rem; }
  */
}

