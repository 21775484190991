.meeting-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 60px);
  /* Adjust based on your header height */
}

.meeting-box {
  padding: 30px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
  width: 20%;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid rgb(3, 170, 225);
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin: 10px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.displayName {
  color: #556eea;
  background-color: #eef2ff;
  border-radius: 10px;
  font-size: xx-large;
  padding: 10px 10px;
}

/* .meeting-sdk-element {
    width: 100%;
    max-width: 800px;
    height: 450px;
    margin-top: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  } */