.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;
  font-size: 20px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 1s ease;
  margin: auto;
  margin-top: 4px;
  margin-bottom: 4px;
}

.action-button .button-icon {
  margin-right: 10px;
  height: 30px;
}

.action-button:hover {
  background-color: rgb(2, 104, 124);
}

.action-button:disabled {
  background-color: #5f5d5d !important;
  cursor: progress;
}
