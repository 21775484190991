.row {
  display: flex;
  align-items: center;
  /* Centers children vertically */
  height: calc(100vh - 60px);
}

.column {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.column:first-child {
  flex-basis: 60%;
}

.column.vitals {
  flex-basis: 40%;
  margin: 20px 0 20px 0;
  height: 100%;
}

#meetingSDKElement {
  margin: auto;
}

.flexContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  margin: auto;
}

.vitalsNumbersRow {
  flex: 0 0 100%;

  display: flex;
  flex-direction: column;

  justify-content: center;
}

.vitalsChartsRow {
  flex: 0 0 60%;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #999;
}

.measurementInfo {
  display: flex;
  flex-direction: row;
  color: rgb(85, 110, 230);
  background-color: #eef2ff;
  border-radius: 10px;
  margin: 0.2rem;
  font-size: 24px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  align-self: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid rgb(3, 170, 225);
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin: 10px auto;
}

.measurementInfoContainer{
  display: flex;
  align-self: center;
  align-items: center;
}

.vitals-loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #556ee6;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  margin: 10px auto;
}
/* Must have a darker background. The text must be bold font and red color */
.interruptionMessage {
  background-color: #dad9d9;
  color: #df4a4a;
  font-weight: bold;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  text-align: center;
  align-self: center;
  width: 80%;
}

/* Should be dark themed with white text */
.invitationInput {
  width: 60%;
  margin-top: 4px;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.invalidInvitationWarning{
  color: red;
  margin-top: 5px;
  align-self: center;
}