.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: rgb(50 63 76);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.modal-button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: rgb(2, 104, 124);
  transition: rgb(2, 104, 124) 1s ease;
  color: white;
  border-radius: 5px;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-button:hover {
  background-color: rgb(5, 188, 224);
  transition: ease-in;
  transition-duration: 200ms; 
}