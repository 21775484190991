body,
html {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
}

main {
  height: calc(100vh - 60px);
  background-color: #f3f3f3;
}

/* Add styles for the header with the logo */
.header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-logo {
  height: 50px; /* Adjust as needed */
}

.zmwebsdk-MuiPaper-root{
  background-color: #535353 !important;
}